
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :append-to-body="true"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
    width="50%"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        :label-width="'120px'"
        :inline="true"
      >
        <el-row>
          <el-form-item label="名称" prop="name">
            <el-input v-model="formModel.name" placeholder="请输入名称"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="描述" prop="desc">
            <el-input v-model="formModel.desc" placeholder="请输入描述" style="width:200%"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="图片" prop="pic">
            <el-upload
              class="avatar-uploader"
              name="photoFile"
              :action="uploadUrl"
              :show-file-list="false"
              :headers="headers"
              :data="uploadData"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="fileUrl" :src="fileUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="费用" prop="cost">
            <el-input v-model="formModel.cost" placeholder="请输入费用"></el-input>
          </el-form-item>
          <el-form-item label="商品类别" prop="type">
            <el-select v-model="formModel.type" placeholder="请选择">
              <el-option
                v-for="type in typeList"
                :key="type.goodsValue"
                :label="type.goodsName"
                :value="type.goodsValue"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="积分使用场景" prop="scenes">
            <el-select v-model="formModel.scenes" placeholder="请选择" style="width:80%">
              <el-option
                v-for="scenes in scenesList"
                :key="scenes.value"
                :label="scenes.name"
                :value="scenes.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="拓展字段1" prop="expand1">
            <el-input v-model="formModel.expand1" placeholder="请输入拓展字段1"></el-input>
          </el-form-item>
          <el-form-item label="拓展字段2" prop="expand2">
            <el-input v-model="formModel.expand2" placeholder="请输入拓展字段2"></el-input>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import goodsInfoApi from "@/api/base/goodsInfo";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import { getToken } from "@/utils/auth"; // get token from cookie

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      formModel: {},
      ruleValidate: {
        name: [{ required: true, message: "名称不能为空", trigger: "blur" }],
        cost: [
          {
            required: true,
            message: "费用不能为空",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "类别不能为空",
            trigger: "blur",
          },
        ],
        scenes: [
          {
            required: true,
            message: "积分使用场景不能为空",
            trigger: "blur",
          },
        ],
      },
      showDialog: true,
      loading: false,
      submitting: false,
      uploadUrl: Constant.serverUrl + "/uploadPicture",
      uploadData: {
        subFolder: "goodsInfo",
      },
      fileUrl: "",
      headers: {
        Authorization: getToken(),
      },
      typeList: [],
      scenesList: [],
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return goodsInfoApi.add(self.formModel);
            } else {
              return goodsInfoApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
    handleAvatarSuccess(res, file) {
      var self = this;
      self.formModel.pic = res.data;
      self.fileUrl =
        res.data + "?x-oss-process=image/resize,m_lfit,h_200,w_200";
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isGIF = file.type === "image/gif";
      const isPNG = file.type === "image/png";
      const isBMP = file.type === "image/bmp";
      // const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isGIF && !isPNG && !isBMP) {
        this.$message.error("上传图片只能是 JPG/GIF/PNG/BMP 格式!");
      }
      // if (!isLt2M) {
      //   this.$message.error("上传图片大小不能超过 2MB!");
      // }
      //return isJPG && isLt2M;
      return isJPG || isGIF || isPNG || isBMP;
    },
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return goodsInfoApi.create();
      } else {
        return goodsInfoApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
          let pic = self.formModel.pic;
          if (pic != null) {
            self.fileUrl =
              pic + "?x-oss-process=image/resize,m_lfit,h_200,w_200";
          }

          var formData = new FormData();
          formData.append("catalogName", "积分使用场景");
          dataDictionaryApi.findByCatalogName(formData).then((response) => {
            var jsonData = response.data;
            this.scenesList = jsonData.data;
          });

          var formData2 = new FormData();
          goodsInfoApi.goodsTypeList(formData2).then((response) => {
            var jsonData = response.data;
            this.typeList = jsonData.data;
          });
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>